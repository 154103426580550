<script setup lang="ts">
import AppListItem from "@/Components/Shared/list/AppListItem.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppList from "@/Components/Shared/list/AppList.vue";
import Logo from "@/Components/Icons/Logo.vue";
import AppMenuItem from "@/Components/Shared/menu/AppMenuItem.vue";
import AppMenu from "@/Components/Shared/menu/AppMenu.vue";
import AppMenuBurger from "@/Components/Shared/menu/AppMenuBurger.vue";
import { onClickOutside } from "@vueuse/core";
import { ref } from "vue";
import useEmitter from "@/Composables/useEmitter";
import {GameEnum, GameEnumLinks} from "@/Enums/GameEnum";
import {authUser} from "@/Utils/Helpers";
const { emitter } = useEmitter();

type Props = {
    openMenu?: boolean;
    game?: string;
};
const { openMenu = false } = defineProps<Props>();

const closeMenu = () => {
    emitter.emit("open-menu");
};
const menu = ref(null);
onClickOutside(menu, () => {
    if (openMenu) {
        closeMenu();
    }
});
</script>

<template>
    <aside
        ref="menu"
        :class="[
            'fixed top-0 lg:left-0 z-40 flex flex-col h-screen',
            openMenu
                ? 'flex w-full max-w-[320px] overflow-auto'
                : 'hidden lg:flex lg:w-[220px]',
        ]"
    >
        <div
            :class="[
                'flex flex-col grow w-full border-r border-gray-300 bg-white transform transition-transform sm:min-h-1',
                openMenu
                    ? 'translate-x-0 w-full lg:max-w-none p-4'
                    : '-translate-x-[100%] lg:translate-x-0',
            ]"
        >
            <AppLink
                href="/"
                class="flex shrink-0 px-4 py-4 hover:bg-slate-100"
            >
                <Logo
                    :class="[
                        'text-primary',
                        openMenu
                            ? 'w-[100px] lg:w-[120px]'
                            : 'w-[80px] lg:w-[148px]',
                    ]"
                />
            </AppLink>

            <AppMenuBurger
                :model-value="openMenu"
                class="lg:hidden absolute top-4 right-4 z-10"
                @click="closeMenu"
            />
            <AppMenu variant="main">
                <AppMenuItem
                    v-if="authUser"
                    href="/feed"
                    :variant="
                        $page.component === 'Feed/Index'
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Jouw Feed
                </AppMenuItem>
                <AppMenuItem
                    href="/posts"
                    :variant="
                        $page.component === 'Posts/Index'
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Posts
                </AppMenuItem>
                <AppMenuItem
                    href="/decks"
                    :variant="
                        $page.component === 'Decks/Index'
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Decks
                </AppMenuItem>

                <AppMenuItem
                    :href="GameEnumLinks.MTG.BASE"
                    :variant="
                        $page.url === GameEnumLinks.MTG.BASE || game === GameEnum.MTG
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Magic: The Gathering
                </AppMenuItem>
                <div class="flex flex-col pl-2">
                    <AppMenuItem
                        :href="GameEnumLinks.MTG.CARDS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url === GameEnumLinks.MTG.CARDS
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Kaarten
                    </AppMenuItem>
                    <AppMenuItem
                        :href="GameEnumLinks.MTG.SETS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url.includes(GameEnumLinks.MTG.SETS)
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Sets
                    </AppMenuItem>
                    <AppMenuItem
                        :href="GameEnumLinks.MTG.POSTS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url === GameEnumLinks.MTG.POSTS
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Posts
                    </AppMenuItem>
                    <AppMenuItem
                        :href="GameEnumLinks.MTG.DECKS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url === GameEnumLinks.MTG.DECKS
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Decks
                    </AppMenuItem>
                </div>
                <AppMenuItem
                    :href="GameEnumLinks.LORCANA.BASE"
                    :variant="
                        $page.url === GameEnumLinks.LORCANA.BASE || game === GameEnum.LORCANA
                            ? 'mainActive'
                            : 'main'
                    "
                >
                    Lorcana
                </AppMenuItem>
                <div class="flex flex-col pl-2">
                    <AppMenuItem
                        :href="GameEnumLinks.LORCANA.CARDS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url === GameEnumLinks.LORCANA.CARDS
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Kaarten
                    </AppMenuItem>
                    <AppMenuItem
                        :href="GameEnumLinks.LORCANA.SETS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url.includes(GameEnumLinks.LORCANA.SETS)
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Sets
                    </AppMenuItem>
                    <AppMenuItem
                        :href="GameEnumLinks.LORCANA.POSTS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url === GameEnumLinks.LORCANA.POSTS
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Posts
                    </AppMenuItem>
                    <AppMenuItem
                        :href="GameEnumLinks.LORCANA.DECKS"
                        class="text-sm"
                        color="grayLight"
                        :variant="
                            $page.url === GameEnumLinks.LORCANA.DECKS
                                ? 'mainActive'
                                : 'main'
                        "
                    >
                        Decks
                    </AppMenuItem>
                </div>

                <AppMenuItem
                    href="/"
                    class="pointer-events-none opacity-50"
                    color="grayLight"
                >
                    Pokemon (binnenkort)
                </AppMenuItem>
            </AppMenu>

            <div class="p-3 flex flex-col shrink-0 border-t">
                <AppList class="grid grid-cols-2">
                    <AppListItem>
                        <AppLink
                            href="/algemene-voorwaarden"
                            size="xsmall"
                        >
                            Voorwaarden
                        </AppLink>
                    </AppListItem>
                    <AppListItem>
                        <AppLink
                            href="/cookies"
                            size="xsmall"
                        >
                            Cookies
                        </AppLink>
                    </AppListItem>
                    <AppListItem>
                        <AppLink
                            href="/over"
                            size="xsmall"
                        >
                            Over
                        </AppLink>
                    </AppListItem>
                    <AppListItem>
                        <AppLink
                            href="/privacy"
                            size="xsmall"
                        >
                            Privacy
                        </AppLink>
                    </AppListItem>
                </AppList>
            </div>
        </div>
    </aside>
    <div
        :class="[
            'fixed top-0 z-30 left-0 w-full h-full bg-black/50',
            openMenu ? 'lt-sm:flex' : 'hidden',
        ]"
    ></div>
</template>
