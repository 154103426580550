<script setup lang="ts">
import { reactive, ref, useAttrs } from "vue";
import { installAntlerComponent } from "@/Utils/component";
import AppFormInputText from "@/Components/Shared/forms/AppFormInputText.vue";

export type SearchSize = "small" | "regular";

type Props = {
    placeholder?: string;
    inputClass?: string;
    label?: string;
    size?: SearchSize;
};

const {
    placeholder = "Producten, merken, gebruikers...",
    inputClass = "",
    label = "",
    size = "regular",
} = defineProps<Props>();

const emit = defineEmits(["update:modelValue", "focus"]);
const attrs = useAttrs();

const classes = {};
const { aClass } = installAntlerComponent("search-input", {}, classes, attrs);

const form = reactive({
    term: "",
});

const handleInput = (value: any) => {
    emit("update:modelValue", value);
};

const searchInput = ref(null);
const handleFocus = () => {
    if (searchInput.value) {
        searchInput.value.handleFocus();
    }
};

defineExpose({ searchInput, handleFocus });
</script>

<template>
    <AppFormInputText
        ref="searchInput"
        v-model="form.term"
        :label="label"
        :input-class="inputClass"
        :placeholder="placeholder"
        name="search"
        :size="size"
        @focus="$emit('focus')"
        @update:model-value="handleInput"
    >
        <template #after></template>
    </AppFormInputText>
</template>
