<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { PropType, ref } from "vue";
import { apiPathUrl } from "@/Utils/Helpers";
import { toast } from "vue-sonner";
import { usePage, router } from "@inertiajs/vue3";
import AppUserAvatar from "@/Components/Shared/user/AppUserAvatar.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";

export type NotificationSize = "small" | "regular";
const deleted = ref(false);

const props = defineProps({
    notification: {
        type: Object,
        required: true,
    },
    subject: {
        type: Object,
        default: null,
    },
    size: {
        type: String as PropType<NotificationSize>,
        default: "regular",
    },
    id: {
        type: String,
        required: true,
    },
    created: {
        type: String,
        default: null,
    },
    read: Boolean,
    reload: Boolean,
});

const classes: AntlerClasses<Props> = {
    base: "flex gap-2 border-b border-slate-100 bg-white last-child:border-b-0",
    variants: {
        size: {
            small: "p-2 gap-2 text-sm",
            regular: "p-5 gap-4",
        },
    },
};

const { aClass } = installAntlerComponent("header", props, classes);

const emit = defineEmits(["deleted", "read"]);

const handleRead = id => {
    axios
        .post(apiPathUrl("/meldingen/" + id))
        .catch(() => {
            // console.log(error);
            toast.error("Er ging iets mis bij het markeren van de melding");
        })
        .then(() => {
            // get data array from resource
            toast.success("De melding is gemarkeerd als gelezen");
            emit("read");

            if (props.reload || usePage().component === "Users/Notifications") {
                router.reload({
                    preserveScroll: true,
                });
            }
        });
};

const handleDelete = id => {
    axios
        .delete(apiPathUrl("/meldingen/" + id))
        .catch(() => {
            toast.error("Er ging iets mis bij het verwijderen van de melding");
        })
        .then(() => {
            // get data array from resource
            toast.success("De melding is verwijderd");
            emit("deleted");

            if (props.reload || usePage().component === "Users/Notifications") {
                router.reload({
                    preserveScroll: true,
                });
            }
        })
        .finally(() => {});
};
</script>

<template>
    <div
        v-if="!deleted"
        :class="
            aClass({
                'bg-slate-50': read,
            })
        "
    >
        <AppUserAvatar
            v-if="notification.user"
            class="mb-auto"
            :user="notification.user"
        />

        <div class="flex flex-col gap-2 grow">
            <div v-if="notification.type === 'new_comment'">
                <AppLink :href="notification.user.path">@{{ notification.user.username }}</AppLink> heeft een
                <AppLink
                    v-if="notification.comment_url"
                    :href="notification.comment_url"
                    class="font-semibold"
                >
                    reactie</AppLink
                >
                geplaatst op jouw {{ subject.type }}
            </div>
            <div v-else-if="notification.type === 'comment_reply'">
                <AppLink :href="notification.user.path">@{{ notification.user.username }}</AppLink> heeft
                <AppLink
                    v-if="subject"
                    :href="subject.path"
                    class="font-semibold"
                >
                    gereageerd</AppLink
                >
                op jouw reactie
            </div>
            <div v-else-if="notification.type === 'mention'">
                <AppLink :href="notification.user.path">@{{ notification.user.username }}</AppLink> heeft je genoemd in een
                <AppLink
                    v-if="subject"
                    :href="subject.path"
                    class="font-semibold"
                    >{{
                        subject.type === "comment" ? "reactie" : "bericht"
                    }}</AppLink
                >
            </div>
            <div v-else-if="notification.type === 'user_follow'">
                <AppLink :href="notification.user.path">
                    @{{ notification.user.username }}
                </AppLink>
                volgt jou
            </div>

            <AppText
                v-if="size === 'regular' && created"
                size="small"
                class="mt-auto"
            >
                {{ created }}
            </AppText>
        </div>
        <AppButtonGroup
            v-if="!notification.actions"
            class="shrink-0 mb-auto"
            gap="none"
        >
            <AppButton
                size="small"
                :disabled="read"
                icon="check"
                :icon-size="size === 'regular' ? 'small' : 'xsmall'"
                variant="ghost"
                :icon-color="read ? 'green' : 'gray'"
                @click="handleRead(id)"
            />

            <AppButton
                v-if="size !== 'small'"
                size="small"
                icon="trash"
                :icon-size="size === 'regular' ? 'small' : 'xsmall'"
                variant="ghost"
                icon-color="gray"
                @click="handleDelete(id)"
            />
        </AppButtonGroup>
    </div>
</template>
