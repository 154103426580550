<script setup lang="ts">
import { computed, onMounted, ref, useSlots, watch } from "vue";
import TheHeader from "@/Components/App/interface/TheHeader.vue";
import TheRoot from "@/Layouts/TheRoot.vue";
import AppLayout from "@/Components/Shared/layout/AppLayout.vue";
import TheAppAlerts from "@/Components/App/interface/TheAppAlerts.vue";
import AppBreadcumbs from "@/Components/App/interface/AppBreadcumbs.vue";
import TheHeaderBar from "@/Components/App/interface/TheHeaderBar.vue";
import TheSidebar from "@/Components/App/interface/TheSidebar.vue";
import TheFooter from "@/Components/App/interface/TheFooter.vue";
import useEmitter from "@/Composables/useEmitter";

type Props = {
    title?: string;
    description?: string;
    image?: string;
    schema?: string;
    breadcrumbs?:
        | {
              label: string;
              path: string;
          }[]
        | string[];
    game?: string;
};
const { title, description, image, schema = undefined } = defineProps<Props>();

const slots = useSlots();
const variants = computed(() => {
    const variants = [];
    variants.push("appMain");

    if (slots.sidebar || slots.sidebarSmall) {
        variants.push("appSidebar");
    }

    if (slots.sidebarleft) {
        variants.push("appSidebarLeft");
    }

    return variants.join(" ");
});

// open menu
const { emitter } = useEmitter();
const openMenu = ref(false);

onMounted(() => {
    emitter.on("open-menu", () => {
        openMenu.value = !openMenu.value;
    });
});
watch(openMenu, value => {
    if (value) {
        document.body.classList.add("overflow-y-hidden");
    } else {
        document.body.classList.remove("overflow-y-hidden");
    }
});
</script>

<template>
    <TheRoot
        :description="description"
        :title="title"
        :image="image"
    >
        <TheHeader
            v-model="openMenu"
            :game="game"
        />

        <AppLayout variant="app">
            <TheHeaderBar
                :open-menu="openMenu"
                :game="game"
            />
            <AppLayout>
                <AppLayout
                    as="main"
                    :variant="variants"
                >
                    <TheSidebar
                        v-if="$slots.sidebarleft"
                        variant="left"
                    >
                        <slot name="sidebarleft" />
                    </TheSidebar>

                    <div
                        :class="[
                            'flex',
                            'flex-col',
                            'grow',
                            'w-full max-h-full overflow-auto',
                        ]"
                        scroll-region
                    >
                        <TheAppAlerts />
                        <AppBreadcumbs
                            v-if="breadcrumbs"
                            :breadcrumbs="breadcrumbs"
                            :game="game"
                        />

                        <slot />

                        <TheFooter class="hidden xl:flex" />
                    </div>

                    <TheSidebar
                        v-if="$slots.sidebar"
                        variant="right"
                        size="regular"
                    >
                        <slot name="sidebar" />
                    </TheSidebar>
                    <TheSidebar
                        v-else-if="$slots.sidebarSmall"
                        variant="right"
                        size="small"
                    >
                        <slot name="sidebarSmall" />
                    </TheSidebar>
                </AppLayout>

                <TheFooter class="xl:hidden" />
            </AppLayout>
        </AppLayout>
    </TheRoot>
</template>
