<script setup lang="ts">
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";

export type SidebarVariant = "left" | "right";
export type SidebarSize = "small" | "regular";

type Props = {
    variant?: SidebarVariant;
    size?: SidebarSize;
};

const { variant = undefined, size = "small" } = defineProps<Props>();

const classes: AntlerClasses<Props> = {
    base: "grow px-6 py-4 lg:py-8 xl:shrink-0 xl:border-l xl:border-gray-100 flex flex-col",
    variants: {
        variant: {
            left: "fixed overflow-auto top-[80px] xl:z-10 left-[100px] lg:left-[220px] right-auto bg-gray-50/50 border-r border-gray-100 h-[calc(100vh-80px-64px)] lt-md:hidden md:w-[220px] xl:w-[220px] 2xl:w-[220px] basis-[220px] xl:basis-[220px]",
            right: "gap-4 sm:gap-8 xl:h-full xl:overflow-auto bg-slate-50 md:grid md:grid-cols-2 md:items-start xl:flex xl:items-stretch",
        },
        size: {
            small: "xl:w-[180px] 2xl:w-[200px]",
            regular: "xl:w-[320px] 2xl:w-[380px]",
        },
    },
};
const { aClass } = installAntlerComponent("header", { size, variant }, classes);
</script>

<template>
    <aside :class="aClass()">
        <slot />
    </aside>
</template>
