<script setup lang="ts">
import { usePage } from "@inertiajs/vue3";
import { onMounted, ref } from "vue";
import AppDropdown from "@/Components/Shared/dropdown/AppDropdown.vue";
import { isLoggedIn } from "@/Utils/Helpers";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppUserNotification from "@/Components/App/notifications/AppUserNotification.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import AppLink from "@/Components/Shared/link/AppLink.vue";

const notifications = ref(null);

onMounted(() => {
    notifications.value =
        usePage().props.tcg.notifications &&
        usePage().props.tcg.notifications.data &&
        usePage().props.tcg.notifications.data.length
            ? usePage().props.tcg.notifications.data
            : null;
});
</script>

<template>
    <AppDropdown
        v-if="isLoggedIn"
        max-height
        variant="centered relative"
        :caret="false"
    >
        <template #toggle>
            <AppButton
                class="relative"
                size="large"
                icon="notification"
                icon-size="regular"
                icon-color="gray"
                variant="ghost"
                label-class="absolute right-0 bottom-0 shrink-0 text-[10px] leading-none bg-theme text-white p-0.5 rounded-full min-w-[16px]"
                :label="notifications ? notifications.length : null"
            />
        </template>

        <div v-if="notifications && notifications.length">
            <div
                v-for="notification in notifications"
                class="text-sm"
            >
                <AppUserNotification
                    :id="notification.id"
                    :notification="notification.data"
                    :subject="notification.subject"
                    size="small"
                    :read="!!notification.read"
                    :created="notification.created"
                    @read="
                        notifications = notifications.filter(
                            n => n.id !== notification.id,
                        )
                    "
                    @deleted="
                        notifications = notifications.filter(
                            n => n.id !== notification.id,
                        )
                    "
                />
            </div>
        </div>
        <AppText
            v-else
            size="small"
            class="text-center mb-2"
        >
            Geen nieuwe meldingen
        </AppText>

        <AppLink
            href="/meldingen"
            variant="buttonSmall"
            class="w-full mt-1"
        >
            Meldingen overzicht
        </AppLink>
    </AppDropdown>
</template>
