<script setup lang="ts">
import AppLink, {
    LinkColor,
    LinkSize,
    LinkVariant,
} from "@/Components/Shared/link/AppLink.vue";
import { computed } from "vue";

type Props = {
    variant?: LinkVariant;
    color?: LinkColor;
    size?: LinkSize;
    label?: string;
    labelClass?: string;
    subject?: object;
};

const {
    variant = "buttonPrimary",
    color = "primary",
    size = "small",
    label = "Post",
    labelClass = undefined,
    subject = undefined,
} = defineProps<Props>();

const postLink = computed(() => {
    let link = `/posts/create`;

    if (subject) {
        if (subject.type === "card") {
            link += `/?card=${subject.id}`;

            if (subject.cardType) {
                link += `&cardType=${subject.cardType}`;
            }
        } else if (subject.type === "set") {
            link += `/?set=${subject.id}`;
        } else if (subject.type === "game") {
            link += `/?game=${subject.id}`;
        }
    }

    return link;
});
</script>

<template>
    <AppLink
        class="h-full"
        :variant="variant"
        :size="size"
        :color="color"
        icon="plus"
        icon-size="xsmall"
        :href="postLink"
    >
        <span :class="labelClass">
            {{ label }}
        </span>
    </AppLink>
</template>
