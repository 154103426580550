<script setup lang="ts">
import { installAntlerComponent } from "@/Utils/component";
const { aClass } = installAntlerComponent("search-wrapper", {});
</script>

<template>
    <div :class="aClass()">
        <slot />
    </div>
</template>
