<script setup lang="ts">
import { onMounted, nextTick, onBeforeMount, ref, useAttrs } from "vue";
import { AntlerClasses, installAntlerComponent } from "@/Utils/component";
import { debounce } from "lodash-es";

type Props = {
    isActive?: boolean;
    autoSize?: boolean;
    position?: string;
};

const {
    isActive = false,
    autoSize = false,
    position = "top",
} = defineProps<Props>();

const attrs = useAttrs();

const classes: AntlerClasses<Props> = {
    base: "absolute left-1/2 -translate-x-1/2 z-40 flex-col w-full min-w-[320px] border border-slate-200 rounded overflow-hidden bg-white shadow-lg",
};

const { aClass } = installAntlerComponent("search-results", {}, classes, attrs);
const results = ref();

// methods
const setHeight = () => {
    const resultsEl = results.value;

    if (typeof window !== "undefined" && resultsEl && window.visualViewport) {
        resultsEl.style.display = "flex";

        const resultsElRect = resultsEl.getBoundingClientRect();

        if (position === "top") {
            resultsEl.style.maxHeight =
                window.visualViewport.height - resultsElRect.top - 4 + "px";
        } else {
            resultsEl.style.maxHeight = resultsElRect.bottom - 4 + "px";
        }
    }
};

// lifecycle
onMounted(() => {
    if (typeof window !== "undefined" && !autoSize && window.visualViewport) {
        nextTick(() => {
            setHeight();
        });

        window.visualViewport.addEventListener(
            "resize",
            debounce(setHeight, 100),
        );
    }
});
onBeforeMount(() => {
    if (typeof window !== "undefined" && !autoSize && window.visualViewport) {
        window.visualViewport.removeEventListener(
            "resize",
            debounce(setHeight),
            100,
        );
    }
});
</script>

<template>
    <div
        ref="results"
        :class="
            aClass({
                'top-full': position === 'top',
                'bottom-full': position === 'bottom',
            })
        "
    >
        <div :class="['w-full overflow-auto', { 'max-h-[200px]': autoSize }]">
            <slot />
        </div>

        <div
            v-if="$slots.after"
            class="flex items-center justify-center py-2 px-4 w-full bg-gray-100"
        >
            <slot name="after" />
        </div>
    </div>
</template>
