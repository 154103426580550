<script setup lang="ts">
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppButtonGroup from "@/Components/Shared/button/AppButtonGroup.vue";
import AppDropdown from "@/Components/Shared/dropdown/AppDropdown.vue";
import AppMenuItem from "@/Components/Shared/menu/AppMenuItem.vue";
import AppMenu from "@/Components/Shared/menu/AppMenu.vue";
import AppText from "@/Components/Shared/text/AppText.vue";
import { authUser, isAdmin, isLoggedIn } from "@/Utils/Helpers";
import Logo from "@/Components/Icons/Logo.vue";
import AppSearchAll from "@/Components/Shared/search/AppSearchAll.vue";
import AppPostButton from "@/Components/App/posts/AppPostButton.vue";
import { Link } from "@inertiajs/vue3";
import AppAvatar from "@/Components/Shared/avatar/AppAvatar.vue";
import AppUserNotifications from "@/Components/App/notifications/AppUserNotifications.vue";
import AppMenuBurger from "@/Components/Shared/menu/AppMenuBurger.vue";
import useEmitter from "@/Composables/useEmitter";

type Props = {
    openMenu?: boolean;
    game?: string;
};
const { openMenu = false, game = undefined } = defineProps<Props>();

const { emitter } = useEmitter();
const toggleMenu = () => {
    emitter.emit("open-menu");
};
</script>

<template>
    <div
        class="fixed top-0 left-0 z-20 flex flex-col w-full border-b-2 border-slate-100 bg-white/70 backdrop-saturate-150 backdrop-blur-lg lg:left-[220px] lg:w-[calc(100%-220px)]"
    >
        <div
            class="flex items-center gap-2 md:gap-5 lg:gap-8 py-1 px-2 sm:px-4 sm:p-5 h-[48px] sm:h-[68px] lg:py-4 lg:px-4"
        >
            <AppLink
                class="lg:hidden"
                href="/"
            >
                <Logo class="w-[80px] sm:w-[100px] text-primary" />
            </AppLink>

            <AppMenuBurger
                :model-value="openMenu"
                class="lg:hidden"
                @click="toggleMenu"
            />

            <AppSearchAll
                class="lt-sm:ml-auto"
                :initial-game="game"
                input-class="pr-8 flex-grow"
            />

            <div class="flex gap-1 sm:gap-2">
                <AppUserNotifications />
                <AppPostButton
                    class="lt-sm:pr-3 lt-sm:pl-3 self-center"
                    label-class="lt-sm:hidden"
                    size="regular"
                />
            </div>

            <div class="shrink-0">
                <AppButtonGroup
                    v-if="!isLoggedIn"
                    variant="nowrap"
                    class="shrink-0"
                >
                    <AppLink
                        class="inline-flex sm:hidden px-2"
                        href="/login"
                        icon="user"
                        color="primary"
                        icon-size="regular"
                    ></AppLink>
                    <AppLink
                        class="hidden text-sm sm:inline-flex md:text-base shrink-0"
                        href="/login"
                    >
                        Login
                    </AppLink>
                    <AppLink
                        href="/register"
                        class="hidden shrink-0 lg:inline-flex font-semibold py-1.5 px-3 border rounded hover:no-underline bg-white hover:bg-slate-100"
                    >
                        Registeren →
                    </AppLink>
                </AppButtonGroup>

                <AppDropdown
                    v-if="isLoggedIn"
                    variant="right"
                    max-height
                >
                    <template #toggle>
                        <AppLink
                            class="flex"
                            :href="authUser.data.path"
                        >
                            <AppAvatar
                                :src="authUser.data.avatar"
                                size="small"
                            />
                        </AppLink>
                    </template>

                    <AppMenu>
                        <AppMenuItem
                            class="flex-row px-2.5 py-1.5 bg-white text-left"
                        >
                            <AppText
                                >Hallo @{{ authUser.data.username }}</AppText
                            >
                        </AppMenuItem>
                        <AppMenuItem :href="authUser.data.path">
                            Profiel
                        </AppMenuItem>
                        <AppMenuItem :href="`${authUser.data.path}/posts`">
                            Posts
                        </AppMenuItem>
                        <AppMenuItem :href="`${authUser.data.path}/decks`">
                            Decks
                        </AppMenuItem>
                        <AppMenuItem
                            :href="`${authUser.data.path}/verlanglijstje`"
                        >
                            Verlanglijstje
                        </AppMenuItem>
                        <AppMenuItem href="/account"> Account </AppMenuItem>
                        <AppMenuItem
                            v-if="isAdmin"
                            href="/!admin"
                            color="primary"
                        >
                            Admin
                        </AppMenuItem>
                        <AppMenuItem class="border-t pt-2">
                            <Link
                                class="text-left w-full px-2.5 py-1.5 hover:bg-gray-100 hover:no-underline text-gray-500"
                                method="post"
                                href="/logout"
                                as="button"
                            >
                                Uitloggen
                            </Link>
                        </AppMenuItem>
                    </AppMenu>
                </AppDropdown>
            </div>
        </div>
    </div>
</template>
