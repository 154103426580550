<script setup lang="ts">
import { nextTick, onMounted, ref, watch } from "vue";
import { useForm, usePage } from "@inertiajs/vue3";
import { onClickOutside } from "@vueuse/core";
import { apiPathUrl } from "@/Utils/Helpers";
import AppHeader from "@/Components/Shared/header/AppHeader.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import AppForm from "@/Components/Shared/forms/AppForm.vue";
import AppSearchInput from "@/Components/Shared/search/AppSearchInput.vue";
import AppButton from "@/Components/Shared/button/AppButton.vue";
import AppSearchResults from "@/Components/Shared/search/AppSearchResults.vue";
import AppSearchWrapper from "@/Components/Shared/search/AppSearchWrapper.vue";
import AppSearchRow from "@/Components/Shared/search/AppSearchRow.vue";
import AppLoader from "@/Components/Shared/loader/AppLoader.vue";
import AppFormInputCheckbox from "@/Components/Shared/forms/AppFormInputCheckbox.vue";
import AppFormInputSelect from "@/Components/Shared/forms/AppFormInputSelect.vue";
import { GameEnum, GameEnumValues } from "@/Enums/GameEnum";

type Props = {
    interaction?: string;
    inputClass?: string;
    exclude?: string;
    ignore?: string[];
    context?: string;
    contextId?: string;
    searchBoxAutoSize?: boolean;
    initialGame?: string;
};

const {
    inputClass = "",
    exclude = undefined,
    ignore = undefined,
    context = undefined,
    contextId = undefined,
    searchBoxAutoSize = false,
    initialGame = undefined,
} = defineProps<Props>();

const cards = ref([]);
const sets = ref([]);
const users = ref([]);
const isActive = ref(false);
const hasSearched = ref(false);
const target = ref(null);
const searchRef = ref(null);
const searchInput = ref(null);
const loading = ref(false);

const form = useForm({
    s: "",
    game: null,
    variants: 0,
});

// methods
onClickOutside(searchRef, () => {
    isActive.value = false;
    showMobileMenu.value = false;
});

const search = (value: any) => {
    const searchValue = value.trimStart();
    isActive.value = searchValue.length > 0;

    if (searchValue.length) {
        loading.value = true;

        const parameters: any = {
            s: "",
            amount: 0,
            exclude: "",
            game: "",
            variants: 1,
        };
        parameters.s = searchValue;
        parameters.amount = 15;
        parameters.variants = form.variants ? 1 : 0;

        if (exclude) {
            parameters.exclude = exclude;
        }
        if (ignore) {
            parameters.ignore = ignore;
        }
        if (context) {
            parameters.context = context;
        }
        if (contextId) {
            parameters.context_id = contextId;
        }
        if (form.game) {
            parameters.game = form.game;
        }

        const searchParams = new URLSearchParams(parameters);
        axios
            .get(apiPathUrl("/search/autocomplete"), {
                params: searchParams,
            })
            .then((response: any) => {
                if (form.game) {
                    cards.value = response.data.cards.data;
                    sets.value = response.data.sets.data;
                }
                users.value = response.data.users.data;
            })
            .finally(() => {
                hasSearched.value = true;
                loading.value = false;
            });
    }
};

const handleSubmit = () => {
    form.transform((data: any) => ({
        ...data,
        variants: data.variants ? 1 : 0,
    })).get("/zoeken", {});
};

onMounted(() => {
    if (usePage().props.game && usePage().props.game.data) {
        form.game = usePage().props.game.data.slug;
    } else if (initialGame) {
        form.game = initialGame;
    }

    // the change of game resets the search value
    nextTick(() => {
        if (usePage().props.filters && usePage().props.filters.s) {
            form.s = usePage().props.filters.s;
        }
    });
});

// watchers
watch(
    () => form.s,
    (newValue, oldValue) => {
        if (oldValue && newValue !== oldValue) {
            isActive.value = true;
        } else {
            isActive.value = false;
        }

        // reset cards
        if (newValue.length === 0) {
            cards.value = [];
        }
    },
    { immediate: false },
);

watch(
    () => form.variants,
    () => {
        if (form.s) {
            search(form.s);
        }
    },
);

watch(
    () => form.game,
    () => {
        cards.value = [];
        sets.value = [];
        form.s = "";
        hasSearched.value = false;
        isActive.value = false;
    },
);

const showMobileMenu = ref(false);

const toggleSearchForm = () => {
    showMobileMenu.value = !showMobileMenu.value;
    if (showMobileMenu.value && searchInput.value) {
        // focus on input, where searchInput which has defineExpose searchInput child which has defineExpose input child
        searchInput.value.handleFocus();
    }
};
</script>

<template>
    <div
        ref="searchRef"
        class="sm:w-full"
    >
        <AppButton
            icon="search"
            icon-size="medium"
            size="small"
            class="inline-flex sm:hidden"
            @click.prevent="toggleSearchForm"
        />
        <AppForm
            ref="target"
            :class="[
                'grow',
                showMobileMenu
                    ? 'lt-sm:block lt-sm:absolute lt-sm:left-0 lt-sm:top-full lt-sm:p-4 lt-sm:bg-white lt-sm:w-full shadow-lg'
                    : 'lt-sm:hidden',
            ]"
            @submit.prevent="handleSubmit"
            @keydown.esc.prevent="isActive = false"
        >
            <AppSearchWrapper class="relative">
                <div class="flex grow">
                    <AppFormInputSelect
                        v-model="form.game"
                        class="w-auto shrink-0 h-[40px]"
                        name="game"
                        variant="fontLarge"
                    >
                        <option value="">Alles</option>
                        <option
                            v-for="game in GameEnum"
                            :value="game"
                            :selected="game === form.game"
                        >
                            {{ GameEnumValues[game] }}
                        </option>
                    </AppFormInputSelect>
                    <div class="relative grow w-full">
                        <AppSearchInput
                            ref="searchInput"
                            v-model="form.s"
                            class="h-[40px]"
                            placeholder="Zoek..."
                            :input-class="inputClass"
                            @focus="isActive = true"
                            @update:model-value="search"
                        />

                        <AppLoader
                            v-if="loading"
                            class="absolute top-1/2 right-2 -translate-y-1/2"
                        />
                    </div>
                    <AppButton
                        type="submit"
                        icon="search"
                        icon-size="regular"
                        color="grayLight"
                    />
                </div>

                <transition
                    enter-active-class="transition ease-out duration-100"
                    enter-from-class="transform opacity-0 scale-95"
                    enter-to-class="transform opacity-100 scale-100"
                    leave-active-class="transition ease-in duration-75"
                    leave-from-class="transform opacity-100 scale-100"
                    leave-to-class="transform opacity-0 scale-95"
                >
                    <AppSearchResults
                        v-if="hasSearched && isActive"
                        :auto-size="searchBoxAutoSize"
                    >
                        <div v-if="form.game" class="py-3 px-4">
                            <AppFormInputCheckbox
                                v-model="form.variants"
                                binary
                                label="toon varianten"
                                name="variants"
                            />
                        </div>

                        <AppLoader
                            v-if="loading"
                            class="m-4"
                        />
                        <template v-else>
                            <template v-if="cards && form.game">
                                <AppHeader
                                    size="none"
                                    class="px-4 py-2 bg-slate-100"
                                >
                                    <AppTitle
                                        as="h4"
                                        size="xxsmall"
                                    >
                                        Kaarten
                                    </AppTitle>
                                </AppHeader>

                                <AppSearchRow
                                    v-for="card in cards"
                                    :item="card"
                                    :href="
                                        form.variants
                                            ? card.path
                                            : card.general_path
                                    "
                                    :is-general="!form.variants"
                                    clickable
                                />
                            </template>

                            <template v-if="sets && sets.length">
                                <AppHeader
                                    size="none"
                                    class="px-4 py-2 bg-slate-100"
                                >
                                    <AppTitle
                                        as="h4"
                                        size="xxsmall"
                                    >
                                        Sets
                                    </AppTitle>
                                </AppHeader>
                                <AppSearchRow
                                    v-for="set in sets"
                                    :item="set"
                                    :href="set.path"
                                    clickable
                                />
                            </template>

                            <template v-if="users && users.length">
                                <AppHeader
                                    size="none"
                                    class="px-4 py-2 bg-slate-100"
                                >
                                    <AppTitle
                                        as="h4"
                                        size="xxsmall"
                                    >
                                        Leden
                                    </AppTitle>
                                </AppHeader>
                                <AppSearchRow
                                    v-for="user in users"
                                    :item="user"
                                    :href="user.path"
                                    clickable
                                />
                            </template>
                        </template>

                        <template #after>
                            <slot name="after-results" />
                        </template>
                    </AppSearchResults>
                </transition>
            </AppSearchWrapper>
        </AppForm>
    </div>
</template>
